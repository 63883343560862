import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/sharp-light-svg-icons"

const MobileMenuSlider = ({
  item,
  index,
  openMainSlider,
  setOpenMainSlider,
  openSubSlider,
  setOpenSubSlider,
}) => {
  return (
    <div>
      {item.sub_navigation?.length > 0 ? (
        <>
          <button
            className="flex w-full py-3 pl-4 pr-0 btn hover:bg-ro-gray-75"
            onClick={() => {
              setOpenMainSlider(index)
            }}
          >
            {item.title}
            <div className="flex items-center justify-center w-12 h-6 ml-auto">
              <FontAwesomeIcon
                icon={faChevronRight}
                size="1x"
                className="inline-block"
              />
            </div>
          </button>
          <div
            className={`absolute top-0 transform ${
              openMainSlider === index ? "" : "translate-x-full"
            } w-full h-[calc(100dvh-6rem)] bg-ro-gray-50 z-[130] transition-transform duration-300`}
          >
            <div className="flex flex-col justify-between h-full overflow-y-auto">
              <div>
                <button
                  className="flex w-full py-3 pl-0 pr-4 btn hover:bg-ro-gray-75"
                  onClick={() => {
                    setOpenMainSlider(null)
                  }}
                >
                  <div className="flex items-center justify-center w-12 h-6 -mr-2">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      size="1x"
                      className="inline-block"
                    />
                  </div>
                  Zurück
                </button>
                <div className="w-full px-4 py-3 font-bold border-b border-ro-gray-200 btn">
                  {item.title}
                </div>
                {item.sub_navigation?.map((subItem, subindex) => (
                  <div key={`nav_sub_item_${subindex}`}>
                    {subItem.sub_navigation?.length > 0 ? (
                      <>
                        <button
                          className="flex w-full py-3 pl-4 pr-0 btn hover:bg-ro-gray-75"
                          onClick={() => {
                            setOpenSubSlider(subindex)
                          }}
                        >
                          {subItem.title}
                          <div className="flex items-center justify-center w-12 h-6 ml-auto">
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              size="1x"
                              className="inline-block"
                            />
                          </div>
                        </button>
                        <div
                          className={`absolute top-0 transform ${
                            openSubSlider === subindex ? "" : "translate-x-full"
                          } w-full h-[calc(100dvh-6rem)] bg-ro-gray-50 z-[130] transition-transform duration-300`}
                        >
                          <div className="flex flex-col justify-between h-full overflow-y-auto">
                            <div>
                              <button
                                className="flex w-full py-3 pl-0 pr-4 btn hover:bg-ro-gray-75"
                                onClick={() => {
                                  setOpenSubSlider(null)
                                }}
                              >
                                <div className="flex items-center justify-center w-12 h-6 -mr-2">
                                  <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    size="1x"
                                    className="inline-block"
                                  />
                                </div>
                                Zurück
                              </button>
                              <div className="w-full px-4 py-3 font-bold border-b border-ro-gray-200 btn">
                                {subItem.title}
                              </div>
                              {subItem.sub_navigation?.map(
                                (subSubItem, subsubindex) => (
                                  <Link
                                    className="flex w-full px-4 py-3 btn hover:bg-ro-gray-75"
                                    link={subSubItem.link}
                                    key={`nav_sub_sub_item_${subsubindex}`}
                                  >
                                    {subSubItem.title}
                                  </Link>
                                )
                              )}
                            </div>
                            <Link
                              className="flex w-full py-3 pl-4 pr-0 mb-4 border-t border-ro-gray-200 btn hover:bg-ro-gray-75"
                              link={subItem.link}
                            >
                              Übersicht {/* @todo translation */}
                              <div className="flex items-center justify-center w-12 h-6 ml-auto">
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  size="1x"
                                  className="inline-block"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Link
                        className="flex w-full px-4 py-3 btn hover:bg-ro-gray-75"
                        link={subItem.link}
                      >
                        {subItem.title}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
              <Link
                className="flex w-full py-3 pl-4 pr-0 mb-4 border-t border-ro-gray-200 btn hover:bg-ro-gray-75"
                link={item.link}
              >
                {item.overview_text}
                <div className="flex items-center justify-center w-12 h-6 ml-auto">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    size="1x"
                    className="inline-block"
                  />
                </div>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <Link
          className="flex w-full px-4 py-3 btn hover:bg-ro-gray-75"
          link={item.link}
        >
          {item.title}
        </Link>
      )}
    </div>
  )
}

export default MobileMenuSlider
