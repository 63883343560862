import React, { useState, Fragment } from "react"
import { Helmet } from "react-helmet"
import { Menu, Transition } from "@headlessui/react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import DefaultCard from "../../../../components/molecules/DefaultCard"
import IconCard from "../../../../components/molecules/IconCard"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/sharp-light-svg-icons"

import { faArrowRight as faArrowRightBold } from "@fortawesome/sharp-solid-svg-icons"

const MainNav = ({ navData, showMenuState, setShowMenuState }) => {
  //const [showMenuState, setShowMenuState] = useState(null)
  const [showSubmenuState, setShowSubmenuState] = useState(0)

  const toggleMenu = (index) => {
    if (showMenuState === index) {
      setShowMenuState(null)
      setShowSubmenuState(0)
    } else if (index == null) {
      setShowMenuState(null)
      setShowSubmenuState(0)
    } else {
      setShowMenuState(index)
      setShowSubmenuState(0)
    }
  }

  const toggleSubmenu = (index) => {
    if (showSubmenuState === index) {
      setShowSubmenuState(null)
    } else {
      setShowSubmenuState(index)
    }
  }

  return (
    <>
      {showMenuState !== null && (
        <Helmet
          htmlAttributes={{
            class: "overflow-x-hidden overflow-hidden",
          }}
          bodyAttributes={{
            class: "theme-default font-body overflow-x-hidden overflow-hidden",
          }}
        />
      )}
      {/* ++++++++++++++++++++++++++++++ Navitems des 1. Levels ++++++++++++++++++++++++++++++*/}
      <div className="flex flex-row mx-auto max-w-screen-2xl lg:px-6">
        {navData?.map((item, index) => (
          <div key={index}>
            {item?.sub_navigation?.length > 0 ? (
              <button
                className={`btn px-4 pt-5 pb-[1.125rem] border-b-2  ${
                  showMenuState === index
                    ? "bg-ro-gray-50 border-b-red-500"
                    : "border-b-transparent hover:bg-ro-gray-100 hover:border-b-ro-gray-100 active:bg-ro-gray-200 active:border-b-red-500"
                }`}
                onClick={() => toggleMenu(index)}
              >
                <span>{item.title}</span>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="1x"
                  className={`inline-block h-4 w-4 ml-2 -mt-0.5 align-middle transition-all duration-300 transform ${
                    showMenuState === index ? "rotate-180" : ""
                  }`}
                />
              </button>
            ) : (
              <Link
                className="py-5 btn hover:bg-ro-gray-100 active:bg-ro-gray-200 "
                link={item.link?.cached_url}
              >
                {item.title}
              </Link>
            )}
            {/* ++++++++++++++++++++++++++++++ Das Menü ++++++++++++++++++++++++++++++*/}

            {item?.sub_navigation?.length > 0 && (
              <Transition
                as={Fragment}
                show={showMenuState === index}
                enter="transition duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-x-0 z-50 overflow-hidden border-t border-ro-gray-200 top-full">
                  <div className="bg-ro-gray-50">
                    <div className="px-6 mx-auto max-w-screen-2xl">
                      <div className="flex flex-row flex-nowrap">
                        {/* ++++++++++++++++++++++++++++++ Linke Seite des Menüs ++++++++++++++++++++++++++++++*/}

                        <div className="flex flex-col min-w-[250px] justify-between overflow-hidden border-r border-ro-gray-200 header-max-menu-height">
                          <div className="overflow-y-auto header-max-menu-height">
                            {/* ++++++++++++++++++++++++++++++ Navitems des 2. Levels ++++++++++++++++++++++++++++++*/}

                            <ul>
                              {item?.sub_navigation.map((subItem, index) => {
                                return (
                                  <li key={index}>
                                    <div className="relative ">
                                      <>
                                        {subItem?.sub_navigation?.length > 0 ? (
                                          <button
                                            className={`flex items-center py-3 justify-between w-full text-left btn hover:bg-ro-gray-150 ${
                                              showSubmenuState === index
                                                ? " bg-ro-gray-100"
                                                : ""
                                            }`}
                                            onClick={() => toggleSubmenu(index)}
                                          >
                                            {subItem.title}
                                            <FontAwesomeIcon
                                              icon={faChevronRight}
                                              size="1x"
                                              className={`inline-block h-4 ml-auto -mt-0.5 self-center transition-transform duration-300 transform ${
                                                showSubmenuState === index
                                                  ? "rotate-180"
                                                  : ""
                                              }`}
                                            />
                                          </button>
                                        ) : (
                                          <Link
                                            className="flex py-3 text-left text-ro-gray-900 btn hover:bg-ro-gray-150"
                                            link={`/${subItem.link?.cached_url}`}
                                          >
                                            {subItem.title}
                                          </Link>
                                        )}
                                      </>
                                    </div>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                          {/* ++++++++++++++++++++++++++++++ Übersicht-Link zum Navitem des 1. Levels ++++++++++++++++++++++++++++++*/}
                          <div className="border-t border-ro-gray-200">
                            <Link
                              className="flex w-full text-left btn hover:bg-ro-gray-150"
                              link={item.link}
                            >
                              {item.overview_text}
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                size="1x"
                                className="inline-block h-4 ml-auto -mt-0.5 self-center"
                              />
                            </Link>
                          </div>
                        </div>
                        {/* ++++++++++++++++++++++++++++++ Rechte Seite des Menüs ++++++++++++++++++++++++++++++*/}
                        <div className="w-full overflow-y-auto header-max-menu-height">
                          {item?.sub_navigation[showSubmenuState]
                            ?.sub_navigation?.length > 0 && (
                            <>
                              {/* ++++++++++++++++++++++++++++++ Title, Description und Link vom 2. Level ++++++++++++++++++++++++++++++*/}

                              <div className="p-8">
                                {item?.sub_navigation[showSubmenuState]?.title
                                  ?.length > 0 && (
                                  <Link
                                    className="inline-block text-xl font-bold hover:text-ro-red"
                                    link={
                                      item?.sub_navigation[showSubmenuState]
                                        .link
                                    }
                                  >
                                    {
                                      item.sub_navigation[showSubmenuState]
                                        .title
                                    }
                                    {item?.sub_navigation[showSubmenuState].link
                                      .cached_url?.length > 0 && (
                                      <FontAwesomeIcon
                                        icon={faArrowRightBold}
                                        size="1x"
                                        className="inline-block w-4 ml-3 -mt-0.5 align-middle"
                                      />
                                    )}
                                  </Link>
                                )}
                                {item?.sub_navigation[showSubmenuState]
                                  ?.description?.length > 0 && (
                                  <span className="block mt-2 text-sm">
                                    {
                                      item?.sub_navigation[showSubmenuState]
                                        ?.description
                                    }
                                  </span>
                                )}
                              </div>
                              {/* ++++++++++++++++++++++++++++++ Navitems des 3. Levels ++++++++++++++++++++++++++++++*/}

                              <div className="w-full">
                                <ul className="flex flex-row flex-wrap w-full h-full pb-4 pl-4 justify-items-stretch">
                                  {item?.sub_navigation[
                                    showSubmenuState
                                  ].sub_navigation.map((subSubItem, index) => {
                                    return (
                                      <li
                                        className="w-1/2 mb-8 xl:w-1/3 px-grid"
                                        key={index}
                                      >
                                        {subSubItem?.image?.filename?.length >
                                        0 ? (
                                          <DefaultCard
                                            image={subSubItem.image}
                                            title={subSubItem.title}
                                            text={subSubItem.description}
                                            link={subSubItem.link}
                                            link_text={subSubItem.link_text}
                                            type="white"
                                            image_ratio="4by3"
                                          />
                                        ) : (
                                          <IconCard
                                            icon={subSubItem.icon}
                                            title={subSubItem.title}
                                            text={subSubItem.description}
                                            link={subSubItem.link}
                                            link_text={subSubItem.link_text}
                                            type="white"
                                          />
                                        )}
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            )}
          </div>
        ))}
      </div>
      {showMenuState !== null && (
        <div
          onClick={() => toggleMenu(null)}
          className="absolute z-30 top-full inset-x-0 h-[100dvh] bg-ro-black/50"
        ></div>
      )}
    </>
  )
}

export default MainNav
